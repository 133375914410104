import { defineAsyncComponent } from "vue";
import moment from "moment";
import raiseMoneyService from "../../../service/raiseMoney.service";
import bouncer from "../../../../../helpers/bouncer";
import ConfirmDialog from "primevue/confirmdialog";
import FileSaver from "file-saver";
import {
    dateFormats,
    numberFormat,
    typeNotification,
    typePayment
} from "@/helpers/consts";
import service from "@/modules/enrollment/service/payment.service";

export default {
    mixins: [bouncer],
    name: "RaiseMoneyDetailPayment",
    components: {
        ConfirmDialog,
        payments: defineAsyncComponent(() => import("../SidebarPayments/"))
    },
    data() {
        return {
            documents: [],
            agreements: [],
            changeStatusItem: null,
            status: [
                "PARCIALMENTE NO COBRADO",
                "PARCIALMENTE COBRADO",
                "NO COBRADO",
                "COBRADO"
            ],
            typeCharges: [
                { name: "Cobro Presencial", code: "PRESENCIAL" },
                { name: "Cobro Online", code: "ONLINE" }
            ],
            typeCharge: { name: "Cobro Online", code: "ONLINE" },
            datePayment: null,
            modalDate: false,
            country: null,
            detailPaymentSelected: null,
            paymentsSidebar: false,
            changeEdit: false,
            dateAssignment: "",
            idAssignment: "",
            loading: false,
            raiseMoney: {},
            submitted: false,
            employees: [],
            months: [],
            weeks: [],
            subsidiaries: [],
            companies: [],
            organizations: [],
            payments: [],
            plans: [],
            origins: [],
            startDate: null,
            endDate: null,
            dialogStatus: false,
            dialogFiles: false,
            paymentAgreementDescription: "",
            paymentAgreement: null,
            sum: 0,
            drag: null,
            validity: null,
            raiseMoneyCountryName: "",
            raiseMoneyCityName: "",
            raiseMoneyDateFirstPayment: "",
            raiseMoneyPlanCost: "",
            raiseMoneyPlanName: "",
            raiseMoneyMonthMonth: "",
            raiseMoneyWeekWeek: "",
            raiseMoneyReferralNote: "",
            raiseMoneyBalance: "",
            raiseMoneyStatus: "",
            raiseMoneyRegistrationOriginDescription: "",
            raiseMoneyOrganizationDescription: "",
            raiseMoneyEmployeeName: "",
            raiseMoneySubsidiaryName: "",
            raiseMoneyIsHolderStudent: "",
            raiseMoneyPlanTotalValue: "",
            raiseMoneyContractHolderDni: "",
            raiseMoneyContractHolderEmail: "",
            raiseMoneyContractHolderName: "",
            raiseMoneyContractHolderLastName: "",
            raiseMoneyContractHolderPhone: "",
            raiseMoneyContractHolderMobilePhone: "",
            raiseMoneyContractHolderCivilStatus: "",
            raiseMoneyContractHolderBirthDate: "",
            raiseMoneyContractHolderCountryName: "",
            raiseMoneyContractHolderCityName: "",
            raiseMoneyContractHolderMainStreet: "",
            raiseMoneyContractHolderSecondStreet: "",
            raiseMoneyContractHolderHouseNumber: "",
            raiseMoneyContractHolderPostalCode: "",
            raiseMoneyContractHolderMyTypeHousing: "",
            raiseMoneyContractHolderCompanyName: "",
            raiseMoneyContractHolderFullCompanyFhone: "",
            raiseMoneyContractHolderCompanyEmail: "",
            raiseMoneyContractHolderCompanyAddress: "",
            raiseMoneyContractHolderMyTypeEmployee: "",
            raiseMoneyContractHolderDateAdmission: "",
            raiseMoneyContractHolderPosition: "",
            raiseMoneyContractHolderFacebook: "",
            raiseMoneyContractHolderSkype: "",
            raiseMoneyContractHolderMonthlyIncome: "",
            raiseMoneyContractHolderObservation: ""
        };
    },
    async mounted() {
        if (this.canAccess("cobranzas_cuentas")) {
            const type = "bread/setItems";
            const payload = [
                {
                    label: "Matricula para gestión de Cobranza",
                    url: "javascript:void(0);"
                }
            ];
            this.$store.dispatch(type, payload);
            await this.getData();
        }
    },
    methods: {
        notification(severity, summary, detail) {
            this.$toast.add({
                severity: severity,
                summary: summary,
                detail: detail,
                life: this.$utils.toastLifeTime()
            });
        },
        async addPaymentAgreement() {
            this.loading = true;
            await raiseMoneyService
                .addAgreement(this.$route.params.enrollment, {
                    date: this.paymentAgreement,
                    observation: this.paymentAgreementDescription
                })
                .then(async response => {
                    const summary = "info";
                    const detail = "Acuerdo de pago grabado correctamente";
                    this.notification(typeNotification.INFO, summary, detail);
                    this.paymentAgreementDescription = "";
                    this.paymentAgreement = null;
                    this.agreements = (await response.data.items) ?? [];
                    this.loading = false;
                })
                .catch(error => {
                    const message = error.response.data;
                    const summary = "error";
                    const errorDetail = this.$utils.formatError(message);
                    this.notification(typeNotification.ERROR, summary, errorDetail);
                    this.loading = false;
                });
        },
        exportReport() {
            this.loading = true;
            raiseMoneyService
                .downloadData({
                    sequential: this.raiseMoney.sequential
                })
                .then(response => {
                    this.info = response.data;
                    const byteCharacters = atob(response.data);
                    const byteNumbers = new Array(byteCharacters.length);
                    for (let i = 0; i < byteCharacters.length; i++) {
                        byteNumbers[i] = byteCharacters.charCodeAt(i);
                    }
                    const byteArray = new Uint8Array(byteNumbers);
                    const options = { type: "application/pdf" };
                    const blob = new Blob([byteArray], options);
                    const name = this.raiseMoney.sequential + ".pdf";
                    FileSaver.saveAs(blob, name);
                    this.loading = false;
                })
                .catch(error => {
                    const message = error.response.data;
                    const errorDetail = this.$utils.formatError(message);
                    const summary = "error";
                    this.notification(typeNotification.ERROR, summary, errorDetail);
                    this.loading = false;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        openDialogStatus(enrollmentFee) {
            this.detailPaymentSelected = enrollmentFee;
            this.dialogStatus = true;
            this.changeStatusItem = this.detailPaymentSelected.status;
        },
        openDialogFiles(paymentId) {
            this.loading = true;
            this.documents = [];
            service.getDocuments(paymentId).then(x => {
                this.documents = x.data;
            }).finally(() => this.loading = false)
            this.dialogFiles = true;
        },
        downloadFile(id) {
            service.getFileStorage(id).then(async response => {
                const newLinkDownload = document.createElement('a');
                newLinkDownload.target = "_blank";
                newLinkDownload.href = response.data;
                newLinkDownload.click();
            }).finally(() => this.loading = false)
        },
        async updateTypeCharge() {
            this.loading = true;
            await raiseMoneyService
                .updateTypeCharge(this.raiseMoney.processed, {
                    typeCharge: this.typeCharge.code
                })
                .then(() => {
                    const summary = "Atención";
                    const errorDetail = "Tipo cobro actualizado correctamente";
                    this.notification(typeNotification.INFO, summary, errorDetail);
                    this.loading = false;
                })
                .catch(error => {
                    const message = error.response.data;
                    const summary = "Error";
                    const errorDetail = this.$utils.formatError(message);
                    this.notification(typeNotification.ERROR, summary, errorDetail);
                    this.loading = false;
                });
        },
        async changeStatus() {
            this.loading = true;
            await raiseMoneyService
                .changeStatus(this.detailPaymentSelected.id, {
                    status: this.changeStatusItem
                })
                .then(() => {
                    this.detailPaymentSelected.status = this.changeStatusItem;
                    const summary = "Atención";
                    const errorDetail = "Cuota actualizada correctamente";
                    this.notification(typeNotification.INFO, summary, errorDetail);
                    this.dialogStatus = false;
                    this.loading = false;
                })
                .catch(error => {
                    const message = error.response.data;
                    const summary = "Error";
                    const errorDetail = this.$utils.formatError(message);
                    this.notification(typeNotification.ERROR, summary, errorDetail);
                    this.loading = false;
                });
        },
        loadFormDate(date) {
            if (this.canAccess("cobranzas_fecha")) {
                this.detailPaymentSelected = date;
                this.datePayment = date.date_payment;
                this.datePayment = moment(
                    this.datePayment,
                    dateFormats.YYYYMMDD
                ).toDate();
                this.modalDate = true;
            }
        },
        async putNewDate() {
            if (this.canAccess("cobranzas_fecha")) {
                this.loading = true;
                await raiseMoneyService
                    .updateDatePayment(this.detailPaymentSelected.id, {
                        date_payment: moment(this.datePayment).format(dateFormats.YYYYMMDD)
                    })
                    .then(() => {
                        this.getData();
                        this.modalDate = false;
                        this.loading = false;
                    })
                    .catch(error => {
                        const message = error.response.data;
                        const errorDetail = this.$utils.formatError(message);
                        const summary = "Error";
                        this.notification(typeNotification.ERROR, summary, errorDetail);
                        this.loading = false;
                    });
            }
        },
        formatMoney(money) {
            const options = {
                style: "currency",
                currency: "USD"
            };
            return new Intl.NumberFormat(numberFormat.EN_US, options).format(money);
        },
        async getData() {
            this.loading = true;
            await raiseMoneyService
                .getItemDetail(this.$route.params.id, this.$route.params.enrollment, {})
                .then(response => {
                    this.raiseMoney = response.data.result;

                    this.raiseMoneyCountryName = this.raiseMoney?.contract?.country?.name;
                    this.raiseMoneyCityName = this.raiseMoney?.contract?.city?.name;
                    this.raiseMoneyDateFirstPayment = this.raiseMoney?.contract?.date_first_payment;
                    this.raiseMoneyPlanName = this.raiseMoney?.plan?.name;
                    this.raiseMoneyPlanCost = this.raiseMoney?.plan?.cost;
                    this.raiseMoneyMonthMonth = this.raiseMoney?.month?.month;
                    this.raiseMoneyReferralNote = this.raiseMoney?.contract?.referral_note;
                    this.raiseMoneyBalance = this.raiseMoney?.balance;
                    this.raiseMoneyStatus = this.raiseMoney?.status;
                    this.raiseMoneyRegistrationOriginDescription = this.raiseMoney?.registration_origin?.description;
                    this.raiseMoneyOrganizationDescription = this.raiseMoney?.registration_origin?.descriptionraiseMoney?.registration_origin?.description;
                    this.raiseMoneyEmployeeName = this.raiseMoney?.employee?.name;
                    this.raiseMoneySubsidiaryName = this.raiseMoney?.subsidiary?.name;
                    this.raiseMoneyIsHolderStudent =
                        this.raiseMoney?.is_holder_student === "y" ? "SI" : "NO";
                    this.raiseMoneyPlanTotalValue = this.raiseMoney?.plan?.total_value;
                    this.raiseMoneyContractHolderDni = this.raiseMoney.contract?.holder?.dni;
                    this.raiseMoneyContractHolderEmail = this.raiseMoney.contract?.holder?.email;
                    this.raiseMoneyContractHolderName = this.raiseMoney.contract?.holder?.name;
                    this.raiseMoneyContractHolderLastName = this.raiseMoney.contract?.holder?.last_name;
                    this.raiseMoneyContractHolderPhone = this.raiseMoney.contract?.holder?.phone;
                    this.raiseMoneyContractHolderMobilePhone = this.raiseMoney.contract?.holder?.mobile_phone;
                    this.raiseMoneyContractHolderCivilStatus = this.raiseMoney.contract?.holder?.civil_status;
                    this.raiseMoneyContractHolderBirthDate = this.raiseMoney.contract?.holder?.birth_date;
                    this.raiseMoneyContractHolderCountryName = this.raiseMoney.contract?.holder?.country?.name;
                    this.raiseMoneyContractHolderCityName = this.raiseMoney.contract?.holder?.city?.name;
                    this.raiseMoneyContractHolderMainStreet = this.raiseMoney.contract?.holder?.main_street;
                    this.raiseMoneyContractHolderSecondStreet = this.raiseMoney.contract?.holder?.second_street;
                    this.raiseMoneyContractHolderHouseNumber = this.raiseMoney.contract?.holder?.house_number;
                    this.raiseMoneyContractHolderPostalCode = this.raiseMoney.contract?.holder?.postal_code;
                    this.raiseMoneyContractHolderMyTypeHousing = this.raiseMoney.contract?.holder?.my_type_housing;
                    this.raiseMoneyContractHolderCompanyName = this.raiseMoney.contract?.holder?.company_name;
                    this.raiseMoneyContractHolderFullCompanyFhone =
                        this.raiseMoney.contract?.holder?.company_phone +
                        " Ext: " +
                        this.raiseMoney.contract?.holder?.company_phone_extension;
                    this.raiseMoneyContractHolderCompanyEmail = this.raiseMoney.contract?.holder?.company_email;
                    this.raiseMoneyContractHolderCompanyAddress = this.raiseMoney.contract?.holder?.company_address;
                    this.raiseMoneyContractHolderMyTypeEmployee = this.raiseMoney.contract?.holder?.my_type_employee;
                    this.raiseMoneyContractHolderDateAdmission = this.raiseMoney.contract?.holder?.date_admission;
                    this.raiseMoneyContractHolderPosition = this.raiseMoney.contract?.holder?.position;
                    this.raiseMoneyContractHolderFacebook = this.raiseMoney.contract?.holder?.facebook;
                    this.raiseMoneyContractHolderSkype = this.raiseMoney.contract?.holder?.skype;
                    this.raiseMoneyContractHolderMonthlyIncome = this.raiseMoney.contract?.holder?.monthly_income;
                    this.raiseMoneyContractHolderObservation = this.raiseMoney.contract?.holder?.observation;
                    this.agreements = this.raiseMoney.processeds?.agreements ?? [];

                    if (
                        this.raiseMoney.processeds.type_of_charge ===
                        [...this.typeCharges].shift().code
                    ) {
                        this.typeCharge = [...this.typeCharges].shift();
                    } else {
                        this.typeCharges.shift();
                        [this.typeCharge] = this.typeCharges;
                    }

                    this.sum = response.data.sum;
                    this.drag = response.data.arrastre;
                    this.validity = response.data.vigence;
                    this.loading = false;
                })
                .finally(() => (this.loading = false));
        },
        getTypePayment(typeCurrentPayment) {
            if (typeCurrentPayment === typePayment.INSCRIPTION.name) {
                return typePayment.INSCRIPTION.label;
            } else if (typeCurrentPayment === typePayment.FEE.name) {
                return typePayment.FEE.label;
            } else {
                return typeCurrentPayment;
            }
        }
    }
};
